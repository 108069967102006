<template>
  <div id="AddDemoAccount">
    <SDrawer title="新增模拟账户" :visible.sync="visible" custom-class="common_account_drawer">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top">
        <el-form-item label="交易平台" prop="tradingPlatform" class="platform_form_item">
          <el-radio-group v-model="form.tradingPlatform" @input="changePlatform">
            <el-radio
              v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)"
              :data-testid="item.value"
              :label="item.value"
              :key="item.index"
            >
              <img src="@/assets/images-1/mt4.png" v-if="item.value === 'mt4'" class="platform_img" />
              <img src="@/assets/images-1/mt5.png" v-if="item.value === 'mt5'" class="platform_img" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="账户类型" prop="accountType">
          <el-select v-model="form.accountType" placeholder="请选择" @change="changeAccountType">
            <el-option
              v-for="item in accountTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :data-testid="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货币类型" prop="currency">
          <el-select v-model="form.currency" placeholder="请选择">
            <el-option
              v-for="item in $config.openLiveAccount.chooseCurrency(regulator)"
              :key="item.currency"
              :label="item.value"
              :value="item.currency"
              :data-testid="item.currency"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="杠杆比例" prop="leverage">
          <el-select v-model="form.leverage" placeholder="请选择">
            <el-option
              v-for="item in $config.openLiveAccount.chooseLeverage(regulator)"
              :key="item"
              :value="item"
              :data-testid="item"
            >
              <span>{{ item }} : 1</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户余额" prop="balance">
          <el-select v-model="form.balance" placeholder="请选择">
            <el-option
              v-for="item in $config.openLiveAccount.chooseBalance(regulator)"
              :key="item.value"
              :value="item.value"
              :label="item.name"
              :data-testid="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="submitClick" data-testid="submit">{{ $t('common.button.submit') }}</el-button>
      </template>
    </SDrawer>
    <SDialog :visible.sync="success">
      <StatusMessage type="success" title="提交成功">
        <template #content>
          <p class="home_status_content" v-html="$t('openDemoAdditAcc.succ')"></p>
        </template>
      </StatusMessage>
      <template #footer>
        <el-button type="primary" @click="onConfirm">确定</el-button>
      </template>
    </SDialog>
  </div>
</template>

<script>
import { apiCreateDemoAccount } from '@/resource'
export default {
  name: 'AddDemoAccount',
  data() {
    return {
      visible: false,
      form: {
        tradingPlatform: `mt4`,
        accountType: null,
        currency: null,
        leverage: null,
        balance: null,
      },
      rules: {
        tradingPlatform: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.platErr') }],
        accountType: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.accTypeErr') }],
        currency: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.currError') }],
        leverage: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.leverageErr') }],
        balance: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.balanceErr') }],
      },
      success: false,
    }
  },
  computed: {
    accountTypeOptions() {
      return this.form.tradingPlatform === 'mt4'
        ? this.$config.openLiveAccount.mt4DemoChooseType(this.regulator)
        : this.$config.openLiveAccount.mt5DemoChooseType(this.regulator)
    },
  },
  methods: {
    changePlatform(e) {
      console.log(e)
    },
    changeAccountType(e) {
      console.log(e)
    },
    createDemoAccount() {
      return apiCreateDemoAccount({ ...this.form })
    },
    submitClick() {
      return this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.createDemoAccount().then(resp => {
            if (resp.data.code === 0) this.success = true
          })
        }
      })
    },
    onConfirm() {
      this.success = false
      this.visible = false
      this.$emit('handleReset')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/views/home/css/addAccount.scss';
</style>
